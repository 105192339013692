import './App.css';

function App() {
  return (
    
   
   <div className="homepage4-body">
   <div className="preloader preloader3">
   <div className="loading-container">
     <div className ="loading"></div>
     <div id="loading-icon">
       <img src="assets/img/logo/logo1.png" alt="" />
     </div>
   </div>
 </div>

 <div className="paginacontainer">
   <div className="progress-wrap">
     <svg
       className="progress-circle svg-content"
       width="100%"
       height="100%"
       viewBox="-1 -1 102 102"
     >
       <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
     </svg>
   </div>
 </div>

 <header>
   <div
     className="header-area homepage4 header header-sticky d-none d-lg-block"
     id="header"
   >
     <div className="container">
       <div className="row">
         <div className="col-lg-12">
           <nav id="navbar-example2" className="navbar">
             <div className="header-elements">
               <div className="site-logo">
                 <a href="index.html"
                   ><img src="assets/img/logo/logo51.png" alt=""
                 /></a>
               </div>
               <div className="main-menu">
                 <ul>
                   <li className="nav-item">
                     <a href="#" className="nav-link active"
                       ><span>Home</span></a
                     >
                   </li>
                   <li className="nav-item">
                     <a href="#about" className="nav-link active"
                       ><span>About US</span></a
                     >
                   </li>
                   <li className="nav-item">
                     <a href="#service" className="nav-link"
                       ><span>Services</span></a
                     >
                   </li>
                   <li className="nav-item">
                     <a href="#works" className="nav-link"
                       ><span>Work Process</span></a
                     >
                   </li>
                   
                 </ul>
               </div>

               <div className="btn-area">
                 <a href="#" className="header-btn12"
                   >Get In Touch <i className="fa-solid fa-arrow-right"></i
                 ></a>
               </div>
             </div>
           </nav>
         </div>
       </div>
     </div>
   </div>
 </header>

 <div className="mobile-header mobile-haeder4 d-block d-lg-none">
   <div className="container-fluid">
     <div className="col-12">
       <div className="mobile-header-elements">
         <div className="mobile-logo">
           <a href="index.html"
             ><img src="assets/img/logo/logo.jpg" alt=""
           /></a>
         </div>
         <div className="mobile-nav-icon dots-menu">
           <i className="fa-solid fa-bars"></i>
         </div>
       </div>
     </div>
   </div>
 </div>

 <div className="mobile-sidebar mobile-sidebar4">
   <div className="logosicon-area">
     <div className="menu-close">
       <i className="fa-solid fa-xmark"></i>
     </div>
   </div>
   <div className="mobile-nav mobile-nav1">
     <ul className="mobile-nav-list nav-list1">
       <li><a href="#">Home </a></li>
       <li className="nav-item">
         <a href="#about" className="nav-link active"><span>About US</span></a>
       </li>
       <li className="nav-item">
         <a href="#service" className="nav-link"><span>Services</span></a>
       </li>
       <li className="nav-item">
         <a href="#works" className="nav-link"><span>Work Process</span></a>
       </li>
       
     </ul>

     <div className="allmobilesection">
       <a href="#" className="header-btn12"
         >Get Started <i className="fa-solid fa-arrow-right"></i
       ></a>
       <div className="single-footer">
         <h3>Contact Info</h3>
         <div className="footer1-contact-info">
           <div className="contact-info-single">
             <div className="contact-info-icon">
               <i className="fa-solid fa-phone-volume"></i>
             </div>
             <div className="contact-info-text">
               <a href="tel:+251937668482">+251 937668482</a>
             </div>
           </div>

           <div className="contact-info-single">
             <div className="contact-info-icon">
               <i className="fa-solid fa-envelope"></i>
             </div>
             <div className="contact-info-text">
               <a href="mailto:architechdesigning@gmail.com"
                 >architechdesigning@gmail.com</a
               >
             </div>
           </div>

           /*-- <div className="single-footer">
         <h3>Our Location</h3>
         
         <div className="contact-info-single">
           <div className="contact-info-icon">
             <i className="fa-solid fa-location-dot"></i>
           </div>
           <div className="contact-info-text">
             <a href="mailto:info@example.com" >55 East Birchwood Ave.Brooklyn, <br/> New York 11201,United States</a>
           </div>
         </div>

       </div> 
           <div className="single-footer">
             <h3>Social Links</h3>

             <div className="social-links-mobile-menu">
               <ul>
                 <li>
                   <a
                     href="https://www.facebook.com/profile.php?id=61562486355631&mibextid=ZbWKwL"
                     ><i className="fa-brands fa-facebook-f"></i
                   ></a>
                 </li>
                 <li>
                   <a href="https://www.instagram.com/architech_designing/"
                     ><i className="fa-brands fa-instagram"></i
                   ></a>
                 </li>
                 <li>
                   <a
                     href="https://www.linkedin.com/company/architechdesigning/"
                     ><i className="fa-brands fa-linkedin-in"></i
                   ></a>
                 </li>
               </ul>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>

 <div className="hero4-section-area">
   <div className="container">
     <div className="row">
       <div className="col-lg-8 m-auto">
         <div className="hero4-header text-center heading7">
           

           <h1 className="text-anime-style-1">
             Choose <span>ArchiTech </span>
             Designs to Dominate Your Market
           </h1>
           <div className="space20"></div>
           <p data-aos="fade-up" data-aos-duration="1000">
             Welcome to Architech Designs, where creativity meets strategy in
             every corner. We specialize in crafting compelling brands
             through innovative design and strategic marketing solutions.
           </p>
         </div>
       </div>
       <div className="space60"></div>
       <div className="col-lg-12">
         <div className="header-images-area">
           <div className="bg-2">
             <img src="assets/img/bg/bg2.png" alt="" />
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
 <div
   data-bs-spy="scroll"
   data-bs-target="#navbar-example2"
   data-bs-root-margin="0px 0px -40%"
   data-bs-smooth-scroll="true"
   className="scrollspy-example bg-body-tertiary rounded-2"
   tabindex="0"
 >
   <div className="about4-section-area sp6" id="about">
     <div className="container">
       <div className="row align-items-center">
         <div className="col-lg-4">
           <div className="about-images">
             <figure className="image-anime reveal">
               <img src="assets/img/all-images/about-img1.png" alt="" />
               
             </figure>
           </div>
         </div>
         <div className="col-lg-5">
           <div className="about-content-area heading8">
             <div className="arrow-circle">
               <a href="about.html"> </a>
             </div>
             <br />
             <h5>About Us</h5>
             <h2 className="text-anime-style-2">
               Comprehensive
               <span>Digital Marketing </span>
               Solutions.
             </h2>
             <p data-aos="fade-up" data-aos-duration="1000">
               Architech Designs is a leading marketing agency dedicated to
               delivering outstanding creative solutions. With a focus on
               customer satisfaction and innovation, we help businesses
               elevate their brand presence.
             </p>
           </div>
         </div>
         <div className="col-lg-3">
           <div className="about-auhtor-images">
             <figure className="image-anime reveal">
               <img src="assets/img/all-images/about-img2.png" alt="about pafe" />
             </figure>
           </div>
         </div>
       </div>
     </div>
   </div>
  
   <div className="service4-section-area sp1" id="service">
     <div className="container">
       <div className="row">
         <div className="col-lg-6 m-auto">
           <div className="service4-header heading8 text-center">
             <h5 data-aos="fade-up" data-aos-duration="1000">Our Service</h5>
             <h2 className="text-anime-style-1">
               Explore Our Dynamic Services: Elevate Your Brand with
               <span>
                 Architech
                 <img src="assets/img/elements/line-img2.png" alt=""
               /></span>
               Designs.
             </h2>
           </div>
         </div>
       </div>
       <div className="row">
         <div className="col-lg-12">
           <div className="service-auhtor-boxes-area">
             <div className="bg-images">
               <div className="img1"></div>
               <div
                 className="icons"
                 data-aos="zoom-in"
                 data-aos-duration="1000"
               ></div>
             </div>
             <div className="row">
               <div
                 className="col-lg-5 col-md-6"
                 data-aos="zoom-out"
                 data-aos-duration="1000"
               >
                 <div className="service-box-area">
                   <div className="icons-area">
                     <div className="icons">
                       <i className="fa-solid fa-globe icon-service"></i>
                     </div>
                     <div className="number">
                       <h4>01</h4>
                     </div>
                   </div>
                   <div className="content-area">
                     <a href="service1.html">Branding</a>
                     <p>
                       Craft a memorable brand identity that connects with
                       your audience and reflects your values.
                     </p>
                   </div>
                 </div>
               </div>
               <div className="col-lg-2 d-lg-block d-none"></div>

               <div
                 className="col-lg-5 col-md-6"
                 data-aos="zoom-out"
                 data-aos-duration="1200"
               >
                 <div className="service-box-area">
                   <div className="icons-area">
                     <div className="icons">
                       <i
                         className="fa-solid fa-house-circle-check icon-service"
                       ></i>
                     </div>
                     <div className="number">
                       <h4>02</h4>
                     </div>
                   </div>
                   <div className="content-area">
                     <a href="service1.html">Interior Design</a>
                     <p>
                       Enhance your physical spaces with innovative design
                       concepts that align with your brand's ethos.
                     </p>
                   </div>
                 </div>
               </div>
               <div className="space100 d-md-block d-none"></div>
               <div className="space100"></div>
               <div
                 className="col-lg-5 col-md-6"
                 data-aos="zoom-in"
                 data-aos-duration="1000"
               >
                 <div className="service-box-area">
                   <div className="icons-area">
                     <div className="icons">
                       <i className="fa-solid fa-rectangle-ad icon-service"></i>
                     </div>
                     <div className="number">
                       <h4>03</h4>
                     </div>
                   </div>
                   <div className="content-area">
                     <a href="service1.html"
                       >System / Digital Designs and Solutions</a
                     >
                     <p>
                       Develop innovative digital solutions that streamline
                       your processes and enhance your system's efficiency
                     </p>
                   </div>
                 </div>
               </div>
               <div className="col-lg-2 d-lg-block d-none"></div>

               <div
                 className="col-lg-5 col-md-6"
                 data-aos="zoom-in"
                 data-aos-duration="1200"
               >
                 <div className="service-box-area">
                   <div className="icons-area">
                     <div className="icons">
                       <i className="fa-solid fa-laptop icon-service"></i>
                     </div>
                     <div className="number">
                       <h4>04</h4>
                     </div>
                   </div>
                   <div className="content-area">
                     <a href="service1.html">Graphic Design</a>
                     <p>
                       Create visually stunning designs that capture
                       attention and communicate your message effectively.
                     </p>
                   </div>
                 </div>
               </div>
               <div className="space100 d-md-block d-none"></div>
               <div className="space100"></div>

               <div
                 className="col-lg-5 col-md-6"
                 data-aos="zoom-in"
                 data-aos-duration="1200"
               >
                 <div className="service-box-area">
                   <div className="icons-area">
                     <div className="icons">
                       <i className="fa-solid fa-folder-open icon-service"></i>
                     </div>
                     <div className="number">
                       <h4>05</h4>
                     </div>
                   </div>
                   <div className="content-area">
                     <a href="service1.html">Content Creation</a>
                     <p>
                       Engage your audience with compelling content tailored
                       to your brand voice and objectives.
                     </p>
                   </div>
                 </div>
               </div>

               
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   
   
  
   <div className="works4-section-area sp1" id="works">
     <div className="container">
       <div className="row">
         <div className="col-lg-6 m-auto">
           <div className="works4-header heading8 text-center">
             <h5 data-aos="fade-up" data-aos-duration="800">Work Process</h5>
             <h2 className="text-anime-style-1">
               <span
                 >ArchiTech
                 <img src="assets/img/elements/line-img1.png" alt=""
               /></span>
               Your Path to Success
             </h2>
             <div className="space10 d-lg-block d-none"></div>
             <p data-aos="fade-up" data-aos-duration="1000">
               Streamlined Excellence in 3 Steps: How We Transform Ideas into
               Impact
             </p>
             <div className="space32"></div>
             
           </div>
         </div>
         <div className="col-lg-1"></div>

         <div className="space50"></div>
       </div>
       <div className="row">
         <div
           className="col-lg-4 col-md-6"
           data-aos="zoom-in"
           data-aos-duration="800"
         >
           <div className="works-author-boxarea">
             <div className="icons">
               <i className="fa-solid fa-wand-magic-sparkles icon-service"></i>
             </div>
             <div className="space24"></div>
             <div className="content-area">
               <a href="case-single.html">Strategy Development</a>
               <p>
                 We dive deep into your vision, uncovering
                 <br className="d-lg-block d-none" />
                 every nuance to ensure clarity and alignment from the start.
               </p>
             </div>
           </div>
         </div>

         <div
           className="col-lg-4 col-md-6"
           data-aos="zoom-in"
           data-aos-duration="1000"
         >
           <div className="works-author-boxarea">
             <div className="icons">
               <i className="fa-brands fa-creative-commons-nd icon-service"></i>
             </div>
             <div className="space24"></div>
             <div className="content-area">
               <a href="case-single.html">Execution</a>
               <p>
                 Our expert team meticulously shapes and refines your
                 project, infusing creativity and precision into every
                 detail.
               </p>
             </div>
           </div>
         </div>

         <div
           className="col-lg-4 col-md-6"
           data-aos="zoom-in"
           data-aos-duration="1200"
         >
           <div className="works-author-boxarea">
             <div className="icons">
               <i className="fa-regular fa-clock icon-service"></i>
             </div>
             <div className="space24"></div>
             <div className="content-area">
               <a href="case-single.html">Delivery</a>
               <p>
                 We deliver exceptional results, surpassing expectations and
                 guaranteeing your project's success with flair and finesse.
               </p>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
   
   <div className="cta4-section-area">
     <img
       src="assets/img/bg/cta-bg5.png"
       alt=""
       className="cta-bg1 aniamtion-key-2"
     />
     <img
       src="assets/img/bg/cta-bg4.png"
       alt=""
       className="cta-bg2 aniamtion-key-1"
     />
     <div className="container">
       <div className="row">
         <div className="col-lg-12 m-auto">
           <div className="cta-header-area text-center sp4 heading2">
             <h2 className="text-anime-style-1">
               Ready To Take Your Brand To <br className="d-md-block d-none" />
               The Next Level
             </h2>
             <p data-aos="fade-up" data-aos-duration="1000">
               Effective strategies not only elevate a brand's visibility but
               also drive <br className="d-md-block d-none" />
               targeted traffic, enhance user experience,
             </p>
             <div
               className="btn-area text-center"
               data-aos="fade-up"
               data-aos-duration="1200"
             >
               
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>

   <div className="footer4-section-area">
     <div className="container">
       <div className="row">
         <div className="col-lg-3 col-md-6">
           <div className="footer-logo-area">
             <img src="assets/img/logo/logo1.png" alt="" height="100px" />
             <p>
               Architech Designs: Where Innovation Meets Strategy, Committed
               to Crafting Compelling Brands with Creative Precision,
               Strategic Vision, and a Human Touch
             </p>
           </div>
         </div>

         <div className="col-lg-2 col-md-6">
           <div className="footer-logo-area1">
             <h3>About Link</h3>
             <ul>
              
               <li><a href="#about">About Us</a></li>
               <li><a href="#service">Services</a></li>
              
             </ul>
           </div>
         </div>

         <div class="col-lg-3 col-md-6">
           <div class="footer-logo-area2">
             <h3>Get in touch</h3>
             <ul>
               <li>
                 <a href="mailto:architechdesigning@gmail.com">
                   <div class="footer-contact">
                     <i class="fa-solid fa-envelope"></i
                     ><span>architech@gmail.com</span>
                   </div>
                 </a>
               </li>
               <li>
                 <a href="tel:123-456-7890"
                   ><div class="footer-contact">
                     <i class="fa-solid fa-phone"></i><span>0937668482</span>
                   </div></a
                 >
               </li>
             </ul>
           </div>
         </div>
         <div className="col-lg-4 col-md-6">
           

           <div className="footer-logo-area">
             <ul>
               <li>
                 <a
                   href="https://www.facebook.com/profile.php?id=61562486355631&mibextid=ZbWKwL"
                   ><i className="fa-brands fa-square-facebook icon-footer"></i
                 ></a>
               </li>
               <li>
                 <a href="https://www.instagram.com/architech_designing/"
                   ><i className="fa-brands fa-instagram icon-footer"></i
                 ></a>
               </li>
               <li>
                 <a
                   href="https://www.linkedin.com/company/architechdesigning/"
                   ><i className="fa-brands fa-linkedin icon-footer"></i
                 ></a>
               </li>
             </ul>
           </div>
         </div>
       </div>
       <div className="space80 d-lg-block d-none"></div>
       <div className="space40 d-lg-none d-block"></div>
       <div className="row">
         <div className="col-lg-12">
           <div className="copyright-area">
             <div className="pera">
               <p>
                 ⓒ Copyright 2024 ArchiTech Designs . All rights reserved
               </p>
             </div>
             
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
 </div>
  

  


  );
}

export default App;
